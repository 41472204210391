import React from 'react'
import { 
  Wrapper, 
  SubContentWrapper, 
  SubContentGridItem
} from '../containers/PageContent'
import Video from './Video'

const PageContent = (props) => {
  return (
    <Wrapper dangerouslySetInnerHTML={{ __html: props.html }} />
  )
}

export const ProjectSubContent = (props) => {
  return (
    <React.Fragment>
      {
        props.typename === 'PrismicProjetBodyHero' ? (
          <SubContentWrapper>
            {
              (props.image.url || props.video.embed_url) ? (
                <div className="intro mixedContent">
                  <div dangerouslySetInnerHTML={{ __html: props.summary.html }}></div>
                  {
                    props.image.url && (
                      <div className="subHeroimageContainer">
                        <img src={props.image.url} alt="dummy" />
                      </div>
                    )
                  }
                  {
                    props.video.embed_url && (
                      <Video {...props.video} />
                    )
                  }
                </div>
              ) : (
                <div className="intro">
                  <div dangerouslySetInnerHTML={{ __html: props.summary.html }}></div>
                  <div></div>
                </div>
              )
            }
          </SubContentWrapper>
        ) : (
          <React.Fragment>
            {
              props.image.url && (
                <SubContentGridItem>
                  <div className="gridImageContainer">
                    <img src={props.image.url} alt="dummy" />
                  </div>
                  <div 
                    className="gridImageLegendContainer"
                    dangerouslySetInnerHTML={{ __html: props.legend.html }}
                  ></div>
                </SubContentGridItem>
              )
            }
            {
              props.video.embed_url && (
                <SubContentGridItem>
                  <Video {...props.video} />
                  <div 
                    className="gridImageLegendContainer"
                    dangerouslySetInnerHTML={{ __html: props.legend.html }}
                  ></div>
                </SubContentGridItem>
              )
            }
          </React.Fragment>
        )
      }
    </React.Fragment>
  )
}
export default PageContent;