import React from 'react'
import { connect } from 'react-redux'
import { graphql } from 'gatsby'
import Header from '../components/Header'
import MainWrapper from '../containers/MainWrapper'
import {
  PageHead,
  PageContent,
  Video,
  Release,
  Project,
  ListItem,
} from '../components'
import { handleModalBanner } from '../actions/modal'
import ModalArtwork from '../components/Modal/ModalArtwork'
import SEO from '../components/SEO'

const Container = (props) => {
  const { __typename } = props;
  let container = [];
  let images = [];

  switch (__typename) {
    case 'PrismicPageBodyHead':
      const { primary } = props
      container.push(<PageHead {...primary} key={props.id} />)
      break;

    case 'PrismicPageBodyContent':
      const { primary: { content_text } } = props
      container.push(<PageContent {...content_text} key={props.id} />)
      break;

    case 'PrismicPageBodyMedia':
      const { items } = props
      items.forEach((element, index) => {
        if (element.diaporama.document) {
          container.push(<div></div>)
        } else if (element.image.url) {
          const currentImage = {
            ...element.image,
            legend: element.legend,
          }
          container.push(
            <ListItem 
              key={`image-${index}`}
              legend={element.legend} 
              {...element.image}
              currentSlide={index}
              onClick={() => props.handleModalBanner({
                modalBanner: !props.modalBanner,
                initialSlide: index
              })}
            />
          )
          images.push(currentImage);
        } else if (element.post.document) {
          container.push(<div></div>)
        } else if (element.project.document) {
          container.push(<Project {...element.project.document} key={element.project.document.id} />)
        } else if (element.realisation.document) {
          container.push(<Release {...element.realisation.document} key={element.realisation.document.id} />)
        } else if (element.video.embed_url) {
          container.push(<Video legend={element.legend} {...element.video} key={props.id} />)
        } else {
          container.push(<div></div>)
        }
      });
      break;
      
    default:
      container.push(<div></div>)
      break;
  }
  
  return (
    <React.Fragment>
      {container}
      <ModalArtwork images={images} />
    </React.Fragment>
  )
}

class Page extends React.Component {
  render() {
    const { 
      modalBanner, 
      handleModalBanner, 
      data: { 
        currentPage: { 
          data: { body, titre, description, opengraph_image } 
        } 
      } 
    } = this.props;
    
    return (
      <React.Fragment>
        <SEO 
          title={titre.text} 
          desc={description.text}
          pathname={this.props.path}
          banner={opengraph_image.localFile.childImageSharp.original.src}
        />
        <Header {...this.props} />
        <MainWrapper>
          {
            body.length > 0 && (
              <React.Fragment>
                {
                  body.map((item, index) => {
                    return (
                      <Container 
                        {...item} 
                        handleModalBanner={handleModalBanner}
                        modalBanner={modalBanner} 
                        key={`body-${index}`}
                      />
                    )
                  })
                }
              </React.Fragment>
            )
          }
        </MainWrapper>
      </React.Fragment>
    )
  }
}

const mapStateToProps = (state) => ({
  ...state.modalBanner
});
const mapDispatchToProps = (dispatch) => ({
  handleModalBanner: (state) => dispatch(handleModalBanner(state))
});
export default connect(mapStateToProps, mapDispatchToProps)(Page);
export const pageQuery = graphql`
  query RealisationsPage($slug: String!) {
    currentPage: prismicPage(uid: {eq: $slug}) {
      data {
        body {
          ... on PrismicPageBodyMedia {
            id
            items {
              project {
                document {
                  ... on PrismicProjet {
                    id
                    data {
                      body {
                        ... on PrismicProjetBodyHero {
                          id
                          primary {
                            image {
                              url
                            }
                            summary {
                              html
                            }
                            video {
                              embed_url
                              provider_name
                              provider_url
                            }
                          }
                        }
                        ... on PrismicProjetBodyMosaique {
                          id
                          items {
                            image {
                              url
                            }
                            legend {
                              html
                              text
                            }
                            video {
                              provider_name
                              provider_url
                              embed_url
                            }
                          }
                        }
                      }
                      titre {
                        html
                      }
                      contenu {
                        html
                      }
                      intro_image {
                        url
                      }
                    }
                  }
                }
              }
              realisation {
                document {
                  ... on PrismicRealisation {
                    id
                    data {
                      contenu {
                        html
                      }
                      credits {
                        html
                      }
                      title {
                        html
                      }
                      titre {
                        html
                      }
                      video {
                        embed_url
                        provider_name
                        provider_url
                      }
                    }
                  }
                }
              }
              video {
                embed_url
                provider_name
                provider_url
              }
              diaporama {
                document {
                  ... on PrismicDiaporama {
                    id
                    data {
                      images {
                        image {
                          url
                        }
                        legende {
                          html
                        }
                      }
                    }
                  }
                }
              }
              image {
                url
                alt
                localFile {
                  childImageSharp {
                    fixed(height: 768, quality: 100) {
                      ...GatsbyImageSharpFixed_withWebp
                    }
                    fluid(maxWidth: 1024, quality: 100) {
                      ...GatsbyImageSharpFluid_withWebp
                    }
                  }
                }
              }
              legend {
                html
                text
              }
              post {
                document {
                  ... on PrismicPost {
                    id
                    data {
                      contenu {
                        html
                      }
                      realisation_link {
                        document {
                          ... on PrismicRealisation {
                            id
                            data {
                              contenu {
                                html
                              }
                              credits {
                                html
                              }
                              titre {
                                html
                              }
                              video {
                                embed_url
                                provider_url
                                provider_name
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                  ... on PrismicPage {
                    id
                    uid
                  }
                }
              }
            }
            primary {
              grid
            }
          }
          ... on PrismicPageBodyContent {
            id
            primary {
              content_text {
                html
              }
            }
          }
          ... on PrismicPageBodyHead {
            id
            primary {
              image {
                url
              }
              summary {
                html
              }
              video {
                embed_url
                provider_name
                provider_url
              }
            }
          }
        }
        description {
          text
        }
        titre {
          text
        }
        opengraph_image {
          localFile {
            childImageSharp {
              original {
                src
              }
            }
          }
        }
      }
    }
  }
`