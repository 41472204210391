import styled from '@emotion/styled'

export const Hero = styled.div`
  font-family: ${props => props.theme.fonts.p.medium};
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 800px;
  margin: 0 auto 5rem;
  h2 {
    padding: 0 20px;
  }
  p {
    padding: 0 20px;
  }
  @media (max-width: ${props => props.theme.breakpoints.s}) {
    display: block;
    h2 {
      padding: 0 0;
      text-align: center;
    }
    p {
      padding: 0 0;
    }
    img {
      display: block;
      margin: 0 auto;
    }
  }
`