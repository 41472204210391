import React from 'react'
import { Wrapper, SubContentGridWrapper } from '../containers/PageContent'
import { VideoFinderWrapper } from '../containers/Media'
import { ProjectSubContent } from './PageContent'

const SubContainer = (props) => {
  const { __typename } = props;
  let container = <div></div>;
  let repeatable = [];
  
  switch (__typename) {
    case 'PrismicProjetBodyHero':
      const { primary } = props
      container = <ProjectSubContent typename={__typename} {...primary} />
      break;

    case 'PrismicProjetBodyMosaique':
      const { items } = props
      items.forEach((item, index) => {
        repeatable.push(<ProjectSubContent typename={__typename} {...item} key={`pagesubcontent-${index}`} />)
      });
      break;

    default:
      container = <div></div>
      break;
  }

  return repeatable.length > 0 ? (
    <SubContentGridWrapper>
      { repeatable.map(item => item) }
    </SubContentGridWrapper>
  ) : container
}

const Project = (props) => {
  const { data } = props;
  return (
    <Wrapper>
      {
        data.intro_image.url && (
          <div className="intro">
            <img src={data.intro_image.url} alt="dummy" />
          </div>
        )
      }

      <VideoFinderWrapper 
        className="content" 
        dangerouslySetInnerHTML={{ __html: data.contenu.html }} 
      />

      {
        data.body.length > 0 && (
          <React.Fragment>
            { 
              data.body.map((item, index) => {
                return <SubContainer {...item} key={`subcontainer-${index}`} />
              })
            }
          </React.Fragment>
        )
      }
    </Wrapper>
  )
}

export default Project;