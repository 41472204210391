import styled from '@emotion/styled'

export const Wrapper = styled.div`
  font-family: ${props => props.theme.fonts.p.medium};
  padding: 0 0;
  margin-bottom: 3rem;
  .intro {
    margin-bottom: 3rem;
    ul {
      padding: 0 0 0 1.2rem;
      li {
        font-size: 15px;
      }
    }
    img {
      width: 100%;
    }
  }
  .content {
    img {
      width: 100%;
    }
  }
`

export const SubContentWrapper = styled.div`
  font-family: ${props => props.theme.fonts.p.medium};
  margin-bottom: 3rem;
  .intro {
    margin-top: 5rem;
    display: flex;
    align-items: center;
    &.mixedContent {
      justify-content: center;
      > div {
        width: 60%;
        &:first-of-type {
          width: 40%;
        }
        &.post {
          padding: 0 0 0;
          &:before {
            display: none;
          }
        }
      }
    }
    img {
      width: 100%;
    }
    @media (max-width: ${props => props.theme.breakpoints.s}) {
      display: block;
      &.mixedContent {
        justify-content: center;
        > div {
          width: 100%;
          &:first-of-type {
            width: 100%;
          }
          &.post {
            padding: 0 0 0;
            &:before {
              display: none;
            }
          }
        }
      }
    }
  }
`

export const SubContentGridWrapper = styled.div`
  font-family: ${props => props.theme.fonts.p.medium};
  position: relative;
  padding: 2rem 0 1rem;
  &:after {
    content: "";
    display: block;
    float: none;
    clear: both;
    width: 100%;
  }
`

export const SubContentGridItem = styled.div`
  font-family: ${props => props.theme.fonts.p.medium};
  width: 25%;
  float: left;
  position: relative;
  display: block;
  padding: .5rem;
  
  > div {
    &.post {
      padding: 0 0 0;
      &:before {
        display: none;
      }
    }
  }

  @media (max-width: ${props => props.theme.breakpoints.s}) {
    width: 100%;
    float: none;
    position: relative;
    padding: 0 0 2rem 0;
  }
`