import React from 'react'
import Img from 'gatsby-image'
import styled from '@emotion/styled'
import Slider from 'react-slick'
import { CustomPrevArrow, CustomNextArrow } from './CustomArrows'
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const InfosContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  h2 {
    &:before {
      content: " - ";
      @media (max-width: ${props => props.theme.breakpoints.s}) {
        display: block;
        content: "";
      }
    }
  }
`;

export default class extends React.Component {

  componentDidMount() {
    window.addEventListener('keydown', this.keyPressHandler);
  }
  
  componentWillUnmount() {
    window.removeEventListener('keydown', this.keyPressHandler);
  }

  keyPressHandler = (e) => {
    if (e.keyCode === 39) {
      this.next();
    } else if(e.keyCode === 37) {
      this.previous();
    }
  }

  next = () => {
    this.slider.slickNext();
  }

  previous = () => {
    this.slider.slickPrev();
  }

  render() {
    const settings = {
      prevArrow: <CustomPrevArrow />,
      nextArrow: <CustomNextArrow />,
      adaptiveHeight: true,
      focusOnSelect: true,
      accessibility: true,
      focusOnChange: true,
      infinite: true,
      speed: 0,
      slidesToShow: 1,
      slidesToScroll: 1,
      initialSlide: this.props.currentSlide || 0,
      dots: false,
      fade: false
    };
    
    return (
      <React.Fragment>
        {
          this.props.images && (
            <Slider ref={c => (this.slider = c)} {...settings}>
              { this.props.images && this.props.images.map((image, index) => {

                return (
                  <div key={`image-${index}`}>
                    <Img 
                      key={index} alt={image.legend.text} 
                      fixed={image.localFile.childImageSharp.fixed} 
                      placeholderStyle={{
                        filter: "grayscale(100%) contrast(100%)",
                        imageRendering: "pixelated",
                        opacity: 0
                      }}
                    />
                    <div style={{margin: '0 auto'}}>
                      <InfosContainer>
                        <div>
                          <h1>{image.legend.text}</h1>&nbsp;
                        </div>
                      </InfosContainer>
                    </div>
                  </div>
                )}
              )}
            </Slider>
          )
        }
      </React.Fragment>
    )
  }
}