import React from 'react'
import YouTube from 'react-youtube';
import Vimeo from '@u-wave/react-vimeo';
import { VideoContainer } from '../containers/Media'
import { getVideoId } from '../utils/mediaHelpers'
import { PostContainer } from '../containers/Post'

const videoOpts = {
  width: '100%',
  playerVars: {
    autoplay: 0,
  },
};

const Video = (props) => {
  return (
    <PostContainer className="post">
      {
        props.legend && (
          <div 
            className="legend" 
            dangerouslySetInnerHTML={{ __html: props.legend.html }}
          ></div>
        )
      }
      
      {
        props.provider_name === 'YouTube' ? (
          <VideoContainer className="youtubeVideoContainer">
            <YouTube 
              videoId={getVideoId('YouTube', props.embed_url)}
              opts={videoOpts} 
            />
          </VideoContainer>
        ) : (
          <VideoContainer className="vimeoVideoContainer">
            <Vimeo 
              video={getVideoId('Vimeo', props.embed_url)}
            />
          </VideoContainer>
        )
      }
    </PostContainer>
  )
}

export default Video;