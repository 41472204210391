import React from 'react'
import YouTube from 'react-youtube';
import Vimeo from '@u-wave/react-vimeo';
import { PostContainer } from '../containers/Post'
import { VideoContainer } from '../containers/Media'
import { getVideoId } from '../utils/mediaHelpers'

const videoOpts = {
  width: '100%',
  playerVars: {
    autoplay: 0,
  },
};

const Release = (props) => {
  const {
    contenu,
    credits,
    title,
    video: { 
      embed_url,
      provider_name
    }
  } = props.data;

  const video = {
    id: getVideoId(provider_name, embed_url)
  };
  return (
    <React.Fragment>
      <PostContainer>
        <h2 className="title" dangerouslySetInnerHTML={{ __html: title.html }}></h2>
        <div className="credits" dangerouslySetInnerHTML={{ __html: credits.html }}></div>
        <div className="content" dangerouslySetInnerHTML={{ __html: contenu.html }}></div>
        {
          embed_url && (
            <React.Fragment>
              {
                provider_name === 'YouTube' ? (
                  <VideoContainer>
                    <YouTube 
                      videoId={video.id} 
                      opts={videoOpts} 
                    />
                  </VideoContainer>
                ) : (
                  <VideoContainer>
                    <Vimeo video={video.id} />
                  </VideoContainer>
                )
              }
            </React.Fragment>
          )
        }
      </PostContainer>
    </React.Fragment>
  )
}

export default Release;