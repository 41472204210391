import React from 'react'
import styled from '@emotion/styled'
import Img from 'gatsby-image'

const Item = styled.li`
  font-family: 'Source Sans Pro', -apple-system, 'BlinkMacSystemFont', 'Segoe UI', 'Roboto', 'Helvetica', 'Arial',
    sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';
  position: relative;
  float: left;
  margin-bottom: 0;
  width: 250px;
  height: 250px;
  overflow: hidden;
  cursor: pointer;
  list-style-type: none;

  @media (max-width: ${props => props.theme.breakpoints.l}) {
    width: 25%; 
    height: auto;
    padding-top: 25%;
  }
  @media (max-width: ${props => props.theme.breakpoints.m}) {
    width: 25%; 
    height: auto;
    padding-top: 25%;
  }
  @media (max-width: ${props => props.theme.breakpoints.xsLandscape}) {
    width: 25%; 
    height: auto;
    padding-top: 25%;
  }
  @media (max-width: ${props => props.theme.breakpoints.xs}) {
    width: 100%; 
    height: auto;
    padding-top: 100%;
    margin-bottom: 2rem;
  }
  
  > div:not(.overlay) {
    position: absolute!important;
    display: block!important;
    top: 0;
    left: 0;
    width: 100%!important;
    height: 100%!important;
  }

  &:hover {
    .overlay {
      visibility:visible;
    }
  }
`
const Overlay = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: rgba(242, 242, 242, 1);
  z-index: 2;
  visibility: hidden;
  div {
    height: 100%;
    display:flex;
    align-items: center;
    justify-content: center;
    p {
      padding: 0 20px;
      text-align: center;
    }
  }
`

export default ({ localFile, url, alt, currentSlide, legend, onClick }) => (
  <React.Fragment>
    {localFile && (
      <Item onClick={onClick}>
        <Overlay className="overlay">
          <div dangerouslySetInnerHTML={{ __html: legend.html }}></div>
        </Overlay>
        <Img
          fixed={localFile.childImageSharp.fixed}
          currentSlide={currentSlide}
          placeholderStyle={{
            filter: "grayscale(100%) contrast(100%)",
            imageRendering: "pixelated",
            opacity: 0,
          }}
        />
      </Item>
    )}
  </React.Fragment>
);
