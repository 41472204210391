import React from 'react'
import { Hero } from '../containers/PageHead'

const PageHead = (props) => {
  return (
    <Hero>
      <img src={props.image.url} alt="dummy" />
      <div dangerouslySetInnerHTML={{ __html: props.summary.html }}></div>
    </Hero>
  )
}

export default PageHead;